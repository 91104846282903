var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "misc-wrapper"
  }, [_c('b-link', {
    staticClass: "brand-logo"
  }, [_c('h2', {
    staticClass: "brand-text text-primary ml-1"
  }, [_vm._v(" " + _vm._s(_vm.appName) + " ")])]), _c('div', {
    staticClass: "misc-inner p-2 p-sm-3"
  }, [_c('div', {
    staticClass: "w-100 text-center"
  }, [_c('h2', {
    staticClass: "mb-1"
  }, [_vm._v(" Kamu tidak memiliki akses kesini ")]), _c('b-button', {
    staticClass: "mb-1 btn-block btn-sm-block",
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.$router.push('/');
      }
    }
  }, [_vm._v("Kembali")]), _c('b-img', {
    attrs: {
      "fluid": "",
      "src": _vm.imgUrl,
      "alt": "Not authorized page"
    }
  })], 1)])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }